@import 'assets/scss/layout/breakpoints';

html {
	height: 100%;
}

.font_preload {
	font-family: Roboto, sans-serif;
}

.App {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	overflow-x: hidden;
	-ms-overflow-style: none; /* Internet Explorer 10+ */

	&::-webkit-scrollbar {
		display: none !important; /* Safari and Chrome */
		background: transparent;
		-webkit-appearance: none;
	}

	&::-webkit-scrollbar {
		display: none !important; /* Safari and Chrome */
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: white;
	}
}

.App-center {
	position: absolute;
	width: 750px;
	top: 0%;
	height: auto;
	border: 1px solid lightgrey;
	overflow-y: scroll !important;
	border-radius: 5px;
	margin: 10px;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		display: none !important; /* Safari and Chrome */
		background: transparent;
	}

	.App-wrapper {
		padding: 5%;
		box-sizing: border-box;
		min-height: calc(100% - 80px);
		height: auto;
		position: relative;
	}

	main {
		height: auto !important;
	}
	header {
		// background-color: #F00;
	}
	main {
		// background-color: #0F0;
	}
	footer {
		// background-color: #00F;
	}
}

@media only screen and (max-width: $responsiveLG) {
	.App-center {
		border: 1px solid lightgrey;
		border-radius: 5px;
		margin: 10px;
		width: 650px;
	}
}

@media only screen and (max-width: $responsiveMD) {
	.App-center {
		margin: 10px;
		border: 1px solid lightgrey;
		border-radius: 5px;
		width: 650px;
	}
}

@media only screen and (max-width: $responsiveSM) {
	.App-center {
		margin: 10px;
		border: 1px solid lightgrey;
		border-radius: 5px;
		width: 600px;
	}
}

@media only screen and (max-width: $responsiveXSM) {
	.App-center {
		border: none;
		transform: none !important;
		border-radius: 0;
		top: 0 !important;
		left: 0 !important;
		margin: 0 !important;
		position: absolute;
		width: 100vw;
		min-height: 100vh;

		.App-wrapper {
			min-height: calc(100vh - 80px);
			padding: 4% !important;
			display: flex;
			flex-flow: column;
		}
	}
}

@media only screen and (max-width: $responsiveMM) {
	.App-wrapper {
		padding: 3% !important;
	}
}
